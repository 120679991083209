import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Content from '../content/Content';
import Footer from '../footer/Footer';
import Nav from '../nav/Nav';
import Module from '../../Module/imdex';
import './app.sass';

const App = () => {
  const { t } = useTranslation();
  const menu = [
    {
      text: t('investor'),
      path: '/',
    },
    {
      text: t('companies'),
      path: '/',
    },
    {
      text: t('educational'),
      path: '/module',
    },
    {
      text: t('accelerator'),
      path: '/',
    },
  ];
  return (
    <main>
      <Nav items={menu} />
      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="/module" element={<Module />} />
      </Routes>
      <Footer />
    </main>
  );
};
export default App;
