import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NewsItems from './../components/news/newsItems/newsItems';
import './module.sass';
const Module = () => {
  const { t } = useTranslation();
  const filters = [
    { text: t('filter1'), link: '/' },
    { text: t('filter2'), link: '/' },
    { text: t('filter3'), link: '/' },
    { text: t('filter4'), link: '/' },
    { text: t('filter5'), link: '/' },
  ];
  const date = [
    {
      img: './img/img1.png',
      date: '13/05/2023',
      theme: t('news1_theme'),
      subtitle: t('news1_subtitle'),
      desc: t('news1_desc'),
    },
    {
      img: './img/img2.png',
      date: '13/05/2023',
      theme: t('news2_theme'),
      subtitle: t('news2_subtitle'),
      desc: t('news2_desc'),
    },
    {
      img: './img/img3.png',
      date: '13/05/2023',
      theme: t('news3_theme'),
      subtitle: t('news3_subtitle'),
      desc: t('news3_desc'),
    },
    {
      img: './img/img1.png',
      date: '13/05/2023',
      theme: t('news1_theme'),
      subtitle: t('news1_subtitle'),
      desc: t('news1_desc'),
    },
    {
      img: './img/img2.png',
      date: '13/05/2023',
      theme: t('news2_theme'),
      subtitle: t('news2_subtitle'),
      desc: t('news2_desc'),
    },
    {
      img: './img/img3.png',
      date: '13/05/2023',
      theme: t('news3_theme'),
      subtitle: t('news3_subtitle'),
      desc: t('news3_desc'),
    },
    {
      img: './img/img1.png',
      date: '13/05/2023',
      theme: t('news1_theme'),
      subtitle: t('news1_subtitle'),
      desc: t('news1_desc'),
    },
    {
      img: './img/img2.png',
      date: '13/05/2023',
      theme: t('news2_theme'),
      subtitle: t('news2_subtitle'),
      desc: t('news2_desc'),
    },
    {
      img: './img/img3.png',
      date: '13/05/2023',
      theme: t('news3_theme'),
      subtitle: t('news3_subtitle'),
      desc: t('news3_desc'),
    },
  ];
  return (
    <div className="module">
      <div className="wrap">
        <div className="module__title">
          {t('module_title')} <span>Expoinvest</span>
        </div>
        <div className="module__desc">{t('module_desc')}</div>
        <div className="filters">
          {filters.map(({ text, link }, i) => (
            <Link to={link} className="filter" key={i}>
              {text}
            </Link>
          ))}
        </div>

        {[...new Array(date.length / 3)].map((item, i) => (
          <div className="news__items">
            {date
              .filter((item, j) => j < (i + 1) * 3 && j >= i * 3)
              .map((item, i) => (
                <NewsItems {...item} key={i} />
              ))}
          </div>
        ))}
        <button className="more">{t('btn_more')}</button>
      </div>
    </div>
  );
};
export default Module;
