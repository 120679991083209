import { HashLink } from 'react-router-hash-link';
import './menu.sass';

const Menu = ({ items }) => {
  console.log(items);
  return (
    <div className="menu">
      {items.map(({ text, path }, id) => (
        <HashLink key={id} to={path} className="menu__item">
          {text}
        </HashLink>
      ))}
    </div>
  );
};
export default Menu;
