import News from '../news/News';
import Faq from '../faq/Faq';
import Header from '../header/Header';
import Services from '../services/Services';
import Steps from '../steps/Steps';

const Content = () => {
  return (
    <div>
      <Header />
      <Services />
      <Steps />
      <News />
      <Faq />
    </div>
  );
};

export default Content;
