import '../news.sass';
const NewsItems = ({ img, date, theme, subtitle, desc }) => {
  return (
    <div className="news__item">
      <div className="news__image">
        <img src={img} alt="" />
      </div>
      <div className="news__details">
        <div className="news__date">{date}</div>
        <div className="news__theme">{theme}</div>
      </div>
      <div className="news__subtitle">{subtitle}</div>
      <div className="news__desc">{desc}</div>
    </div>
  );
};
export default NewsItems;
